import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import MuralData from '../../../content/mural-list.yaml'
import MuralCard from '../../components/mural-card'


const JVMurals = () => {

  return (
    <Layout>

      <SEO title="Juan Velazquez Murals" />

      <div className="mural-page-heading">
        <div className="mural-page-heading__box">
          <h1 className="mural-page-heading__main">Juan Velazquez Murals</h1>
          <p className="mural-page-heading__sub"><em>Fort Worth Artist</em></p>
          <p className="mural-page-heading__text">This page contains the locations of the many murals <a href="https://www.velazquezart.com/">Juan Velazquez</a> is responsible for bringing to life all over the Dallas Fort Worth area. The themes range from historical figures to cultural icons and legendary pro athletes.</p>

          <p className="mural-page-heading__text">Click on the address to be directed to Google Maps.</p>

        </div>
      </div>

      {MuralData.map((data, index) => {
        return <MuralCard 
        key={`content_item_${index}`}
        muralName={data.name}
        muralNote={data.note}
        muralLink={data.link}
        locationName={data.location}
        address={data.address} />
      })}

    </Layout>
  )
}

export default JVMurals
import React from 'react'


const MuralCard = (props) => {

  console.log(props)

  return (
    <div className="mural-card">
    
    <a className="mural-card__link" target="_blank" rel="noopener noreferrer" href={props.muralLink}>
      <div className="mural-card__content-box">
        <h2 className="mural-card__name">{props.muralName}</h2>
        <p className="mural-card__note"><small><em>{props.muralNote}</em></small></p>
        {/* <a className="mural-card__link" target="_blank" rel="noopener noreferrer" href={props.muralLink}>{props.locationName}</a> */}
        <p className="mural-card__address">{props.address}</p>
      </div>
    </a>
    </div>
  )
}

export default MuralCard